@tailwind base;

@import '@fontsource-variable/inter/index.css';

@tailwind components;
@tailwind utilities;

@import "./variables.css";

/* Fonts */
@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-Regular.woff2') format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-Regular.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-SemiBold.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-SemiBold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Red Hat Text';
  font-display: swap;
  src: url('./fonts/RedHatText-SemiBold.woff2') format('woff2');
  font-weight: 800;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  @apply h-full box-border touch-manipulation overflow-y-scroll
}

/* cover background */
body {
  position: relative;
  min-height: 100%;
  margin: 0;
  background-color: transparent !important;
  background-attachment: fixed;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

div.scroll {
  overflow-y: overlay;
}

div.scroll::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

div.scroll::-webkit-scrollbar-thumb {
  @apply bg-black/20 dark:bg-white/20;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
  inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

div.scroll::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

div.scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="search"]::-webkit-search-cancel-button {
    appearance: none;
    -webkit-appearance: none;
  }

  button:focus,
  input:focus,
  a:focus,
  img:focus {
    @apply ring-offset-0 ring-0 outline-offset-0 outline-0 outline-none shadow-none border-0;
  }

  button:focus-within,
  input:focus-within,
  a:focus-within,
  img:focus-within {
    @apply ring-offset-0 ring-0 outline-offset-0 outline-0 outline-none shadow-none border-0;
  }

  button:focus-visible,
  input:focus-visible,
  a:focus-visible,
  img:focus-visible {
    @apply ring-offset-0 ring-0 outline-offset-0 outline-0 outline-none shadow-none border-0;
  }
}

::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
}

::view-transition-old(root) {
  @apply z-[1] dark:z-[9999];
}

::view-transition-new(root) {
  @apply z-[9999] dark:z-[1];
}

.primary-background {
  background: linear-gradient(90deg, #B2F97B 0%, #6ECAFE 59.78%, #A495FF 100%);
}